body, html {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-color: #1e1e1e;
  color: #ffffff;
}

.app {
  display: flex;
  height: 100vh;
}

.sidebar {
  width: 300px;
  background-color: #2c2c2c;
  border-right: 1px solid #3a3a3a;
  display: flex;
  flex-direction: column
}

.sidebar-header {
  padding: 20px;
  border-bottom: 1px solid #3a3a3a;
}

.sidebar-logo {
  padding: 30px;
  text-align: center;
  margin-top: auto; /* This pushes the logo to the bottom */
  border-top: 1px solid #3a3a3a;
}

.sidebar-logo img {
  height: 50px;
}

.contact-list {
  overflow-y: auto;
}

.contact-item {
  display: flex;
  padding: 15px;
  border-bottom: 1px solid #3a3a3a;
}

.contact-info {
  margin-left: 15px;
}

.contact-info h3 {
  margin: 0;
  font-size: 16px;
}

.contact-info p {
  margin: 5px 0 0;
  font-size: 14px;
  color: #a0a0a0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.main-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background-color: #2c2c2c;
  border-bottom: 1px solid #3a3a3a;
}

.header-icons .icon {
  margin-right: 15px;
  font-size: 20px;
}

.user-info {
  display: flex;
  align-items: center;
}

.user-info span {
  margin-right: 10px;
}

.chat-messages {
  flex-grow: 1;
  overflow-y: auto;
  padding: 20px;
}

.message {
  display: flex;
  margin-bottom: 20px;
}

.message.user {
  flex-direction: row-reverse;
}

.message-content {
  background-color: #2E8BEF;
  padding: 10px 15px;
  border-radius: 15px;
  max-width: 70%;
}

.user .message-content {
  background-color: #4a4a4a;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0 10px;
}

.avatar-letter {
  width: 40px;
  height: 40px;
  background-color: purple;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-weight: bold;
  font-size: 20px;
}

.message-input {
  display: flex;
  padding: 20px;
  background-color: #2c2c2c;
}

.message-input input {
  flex-grow: 1;
  padding: 10px;
  border: none;
  border-radius: 20px;
  background-color: #3a3a3a;
  color: #ffffff;
}

.message-input button {
  background: none;
  border: none;
  font-size: 20px;
  margin-left: 10px;
  cursor: pointer;
  color: #ffffff;
}

.loading-dots {
  display: flex;
  align-items: center;
  height: 2em;
}

.loading-dots span {
  font-size: 2em;
  animation-name: loading;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}

.loading-dots span:nth-child(2) {
  animation-delay: .2s;
}

.loading-dots span:nth-child(3) {
  animation-delay: .4s;
}

@keyframes loading {
  0% {
    opacity: .2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: .2;
  }
}

.sign-out-button {
  
  margin-top: 10px;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 10px;
}


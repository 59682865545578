.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #1e1e1e;
  }
  
  .login-form {
    background-color: #2c2c2c;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 300px;
  }
  
  .login-form h2 {
    color: #ffffff;
    margin-bottom: 1.5rem;
    text-align: center;
  }
  
  .form-group {
    margin-bottom: 1rem;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 0.5rem;
    color: #ffffff;
  }
  
  .form-group input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #3a3a3a;
    border-radius: 4px;
    background-color: #3a3a3a;
    color: #ffffff;
  }
  
  .login-button {
    width: 100%;
    padding: 0.75rem;
    background-color: #554EA8;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .login-button:hover {
    background-color: #5642AF; 
  }
  
  .error-message {
    color: #ff6b6b;
    margin-bottom: 1rem;
    text-align: center;
  }